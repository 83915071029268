import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { buildQAQCChartOptionsUrl } from 'state-domains/network';
import { sendRetrieveRequest } from 'state-domains/utils';

import { ChartOptions } from '../types';

export const loadChartOptions = (): Observable<ChartOptions> => {
    const obs = sendRetrieveRequest(buildQAQCChartOptionsUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<ChartOptions>) => {
            return observableOf(response);
        }),
    );
};
